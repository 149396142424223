.preloader {
	@extend %center;
	flex-direction: column;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background: rgb(var(--data-color-background));
	z-index: z('preloader');
	user-select: none;
}

.preloader__loadingbar {
	width: 100%;
	height: 4px;
	position: absolute;
	left: 0;
	bottom: 0;

	background: rgb(var(--data-color-white));

	z-index: z('content');
	transform-origin: left;
	transform: scaleX(0);
}
.preloader__loadingnumber {
	position: absolute;
	bottom: 20px;
	right: 20px;
	color: rgb(var(--data-color-white));
}
.preloader__logo__svg__container {
	position: relative;
	top: 0;
	left: 0;
	overflow: hidden;
	mix-blend-mode: difference;

	z-index: z('content');

	& span {
		display: inline-block;
		// transform: translateY(200);
	}
	& span.spacing {
		margin-left: 20px;
	}

	@include media('<laptop') {
		& * {
			width: 300px;
		}
	}
}
.preloader__svg--stroke {
	stroke-dasharray: 1000;
	stroke-dashoffset: 0;
	stroke: white;
	stroke-width: 2px;
}
.preloader__svg--filled {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) scale(0.5);
}

.preloader__bg {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	visibility: hidden;
}

.preloader__bg--main {
	background: rgb(var(--data-color-background));
	z-index: 1;
}

.preloader__bg--overlay {
	background: rgb(var(--data-color-black));
	z-index: 0;
	// transform: translateY(100%);
}
.preloader__bg.is-animated {
	z-index: 0;
}

.preloader__icon {
	position: relative;
}
.preloader__svg path,
.preloader__svg rect {
	& svg path,
	& svg rect {
		fill: rgb(var(--data-color-black));
	}
}
.preloader__svg--overlay {
	position: absolute;
	top: 0;
	left: 0;
	animation: reveal 2s forwards infinite;
	animation-delay: 1s;
	clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0);
}

@keyframes reveal {
	0% {
		clip-path: polygon(0 0, 0 100%, 0 100%, 0 0);
	}
	50% {
		clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0);
	}
	100% {
		clip-path: polygon(100% 0, 100% 100%, 100% 100%, 100% 0);
	}
}
