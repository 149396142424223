.nav__item {
	max-width: 100%;
	// min-width: 120px;
}
.nav__item__title {
	margin-bottom: 4px;
}
.nav__item__subtitle {
	display: flex;
	flex-direction: column;
	user-select: text;

	a {
		@extend %link--hidden;
		min-height: 18px;
	}

	& span {
		align-self: flex-start;
	}

	&.shift {
		& span:first-child {
			align-self: flex-start;
		}

		& span:nth-child(2) {
			align-self: flex-end;
		}
	}

	@include media('<phone') {
		&.shift {
			min-height: 48px;
			justify-content: space-between;
		}
	}
}
