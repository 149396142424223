.webgl {
	position: fixed;
	top: 0;
	left: 0;
	outline: none;
	opacity: 1;
	z-index: z('content');

	cursor: grab;

	&:active {
		cursor: grabbing;
	}
}
