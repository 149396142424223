nav {
	// position: fixed;
	position: relative;

	width: 100%;
	flex-basis: 15%; /* Adjust these values as necessary */
	max-height: 15%;

	// top: 0;
	// left: 0;
	z-index: z('navigation');
	padding: 0 0;

	transform: translateY(0%);

	@extend %hide-on-laptop;

	@include media('<laptop') {
		width: 100%;
		flex-basis: 0; /* Adjust these values as necessary */
		max-height: 0%;
		display: none;
	}
}

.nav__wrapper {
	@extend %center;

	width: 100%;
	height: 100%;
	// min-height: 6.4rem;
	z-index: 10;
	position: relative;
	padding: 24px 0;
	padding-bottom: 18px;
	user-select: none;
	visibility: hidden;
	opacity: 0;
	margin: auto;

	@include media('<laptop') {
		min-height: 0rem;
	}

	@include media('<phone') {
		display: none;
	}
}

.nav__top {
	width: 100%;
	display: grid;

	gap: 100px;

	margin: auto;

	@include media('<desktop') {
		grid-template-columns: repeat(3, 1fr);
	}
	@include media('>desktop') {
		grid-template-columns: repeat(4, 1fr);
	}
}
