.cover {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-start;
	opacity: 1;
	visibility: visible;

	width: 100%;
	height: 100%;

	z-index: z('content');
	padding: 0 0;

	color: rgb(var(--data-color-white));
}

.cover__wrapper {
	display: flex;
	flex-direction: column;
	width: 100vw;
	height: 100%;
	justify-content: flex-end;
	opacity: 1;
}

.cover__logo {
	position: absolute;
	top: 1.75rem;
	left: 6.5rem;
	max-width: 12.8rem;
	max-height: 12.8rem;

	@include media('<tablet') {
		display: none;
	}
}

.cover__background {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	// filter: blur(40px);
	z-index: -1;
	// opacity: 0;
}
