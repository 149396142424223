.loading__icon__container {
	opacity: 0;
	visibility: hidden;

	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: z('content-back');
}

.loading__icon {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}
.loading__icon div {
	position: absolute;
	top: 33px;
	width: 13px;
	height: 13px;
	// border-radius: 50%;
	background: #fff;
	animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.loading__icon div:nth-child(1) {
	left: 8px;
	animation: loading1 0.6s infinite;
}
.loading__icon div:nth-child(2) {
	left: 8px;
	animation: loading2 0.6s infinite;
}
.loading__icon div:nth-child(3) {
	left: 32px;
	animation: loading2 0.6s infinite;
}
.loading__icon div:nth-child(4) {
	left: 56px;
	animation: loading3 0.6s infinite;
}
@keyframes loading1 {
	0% {
		opacity: 0;
		transform: scale(0);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}
@keyframes loading3 {
	0% {
		opacity: 1;
		transform: scale(1);
	}
	100% {
		opacity: 0;
		transform: scale(0);
	}
}
@keyframes loading2 {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(24px, 0);
	}
}
