@import './cover';
@import './main';
@import './thumb';

main {
	font-size: 3rem;
	width: 100%;
	height: auto;
	position: relative;
}

.home {
	height: 100vh;
	overflow: hidden;

	@include media('<=tablet') {
		height: 90vh;
	}
}
