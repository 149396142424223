*,
*:after,
*:before {
	box-sizing: border-box;
}

:root {
	--data-color-black: #{$color-black};
	--data-color-background: #{$color-background};
	--data-color-white: #{$color-white};
	--data-highlight-color: #ff9f38;
}

html {
	@extend %cover;

	line-height: 1;

	color: rgb(var(--data-color-black));
	background: rgb(var(--data-color-background));
	// overflow: hidden;
	// overflow: hidden;

	overscroll-behavior-x: none;

	cursor: default;

	@include media('<tablet') {
		font-size: calc(100vw / 343 * 10);
		// overflow: auto;
		overflow-x: hidden;
	}

	::selection {
		background: var(--data-highlight-color);
	}
}

body {
	overscroll-behavior-x: none;
	position: relative;
	width: 100%;
}

a {
	text-decoration: none;
	color: inherit;
	outline: none;
}

button {
	background: none;
	border: none;
	color: inherit;
	cursor: pointer;
	outline: none;
}

.dbg {
	display: flex;
	flex-direction: column;
	position: fixed;
	z-index: 9999;
	top: 0;
	left: 0;
	opacity: 0;

	span {
		background: rgba(0, 0, 0, 0.5);
		color: white;
		padding: 5px;
	}
}

h1,
h2 {
	font-family: 'Thunder', 'Trebuchet MS', 'Helvetica', 'Arial';
	color: white;
	font-size: 50px;
	line-height: 36px;
	text-transform: uppercase;

	@include media('>tablet', '<desktop') {
		font-size: 40px;
		line-height: 32px;
	}
}

p {
	font-family: 'Inter', 'Trebuchet MS', 'Helvetica', 'Arial';
	color: white;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
}

img {
	pointer-events: none;
	user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;

	max-width: 100%;
	height: auto;
}

.main-div {
	user-select: none;
}

.content {
	position: relative;
}

.refresh {
	// overflow: hidden;
	// touch-action: none;
}
