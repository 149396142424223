.notfound {
	@extend %page;
	flex-direction: column;
}

.notfound__wrapper {
	@extend %center;
	min-height: 100vh;
	flex-direction: column;
	position: relative;
}

.notfound__title {
	font-size: 256px;
	line-height: 100%;
	@include media('<laptop') {
		font-size: 96px;
	}
}
.notfound__subtitle {
	margin-top: 32px;
	text-align: center;
}
.notfound__link {
	a {
		@extend %link;
		min-height: 18px;
	}
}