.thumb {
	flex-basis: 10%; /* Adjust these values as necessary */


	@include media('<desktop') {
		flex-basis: 114px;
		max-height: 114px;
	}
	@include media('<phone') {
		flex-basis: 96px;
		max-height: 96px;
	}
}
.thumb__wrapper {
	display: flex;
	padding: 2px;
	// opacity: 0;
	visibility: visible;

	z-index: z('content');
	cursor: pointer;

	@include media('>phone') {
		padding: 6px;
	}
}

.thumb__item {
	position: relative;

	height: 176px;
	width: 260px;
	min-width: 260px;

	margin: 6px;
	// opacity: 0;
	@include media('<desktop') {
		width: 130px;
		height: auto;
		min-width: 130px;
	}

	@include media('<phone') {
		width: 130px;
		height: auto;
		min-width: 130px;
		margin: 2px;
	}
}

.thumb__overlay__svg {
	@extend %hidden;
}

.thumb__overlay {
	@extend %center;

	position: absolute;
	left: 0;
	top: 0;
	background: rgba(0, 0, 0, 0);
	width: 100%;
	height: 100%;

	&.active {
		background: rgba(0, 0, 0, 0.5);

		.thumb__overlay__svg {
			@extend %visible;
		}
	}
}

.thumb__image {
	object-fit: cover;
	object-position: center;
	width: inherit;
	height: inherit;
}
